// ** Import Libraries
import React, { useState } from "react";
import { Link } from 'react-router-dom';

// ** Import Custom Components
import ProfileEdit from "./ProfileEdit";
import EditLanguageDropDown from "./EditLanguageDropDown";
import NavSaveCloseButtonSet from "./NavSaveCloseButtonSet";
import RenameEditBar from "./RenameEditBar";
import NavShareDownloadButtonSet from "./NavShareDownloadButtonSet";
import NavUndoRedoButtonSet from "./NavUndoRedoButtonSet";
import EditAnnouncement from "./EditAnnouncement";
import Profile from "../../NavBarComp/Profile";

import whiteLogo from '../../../assets/logo/ORI-white logo.png'

function EditHeader() {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isFlag, setIsFlag] = useState('url("https://tcgimarketing.com/images/flags/en.svg")');
  const [languageButtonPosition, setlanguageButtonPosition] = useState(null)

  const handleFlag = (backgroundImage) => {
    setIsFlag(backgroundImage);
    setIsDropdownOpen(!isDropdownOpen)
  }
  const handleLanguageButtonClick = () => {
    setIsDropdownOpen(!isDropdownOpen);
    updatelanguageButtonPosition()
  }

  const updatelanguageButtonPosition = () => {
    const buttonElement = document.querySelector('.language-switcher');
    if (buttonElement) {
      const buttonRect = buttonElement.getBoundingClientRect();
      // console.log(buttonRect);
      const leftValue = `${((buttonRect.left / buttonRect.right) * 100) - 6.8}%`

      setlanguageButtonPosition({
        top: buttonRect.bottom + window.scrollY,
        left: leftValue,
      });
    }
  };
  return (
    <div className="header" style={{ background: 'linear-gradient(90deg, rgb(202, 182, 125), rgb(31, 31, 31))' }}>
      <Link className="header__image-box" to='/categories'>
        <img className="header__logo" alt="Opulent Reality International" src={whiteLogo} />
      </Link>
      <NavSaveCloseButtonSet />
      <RenameEditBar />
      <NavUndoRedoButtonSet />
      <div className="header__divider"></div>
      <NavShareDownloadButtonSet />
      <EditAnnouncement />
      {/* <ProfileEdit name="Faizan"/> */}
      <Profile />
      {/* Language Section */}
      <i className={`${isDropdownOpen ? "language-switcher language-switcher__flag ms-2 rc-dropdown-open" : "language-switcher language-switcher__flag ms-2"}`} style={{ backgroundImage: isFlag }} onClick={handleLanguageButtonClick} />
      {isDropdownOpen && (<EditLanguageDropDown flag={handleFlag} position={languageButtonPosition} setIsDropdownOpen={setIsDropdownOpen} />)}
    </div>
  )
}

export default EditHeader;